<template>
  <div>
    <OmologazioniList
      :fromCarico="true"
      :id_societa="id_societa"
      :id_omologatore="id_omologatore"
      :tipologie="tipologie"
      :id_disciplina="id_disciplina"
      :rowstoskip="rowstoskip"
      :fetchrows="fetchrows"
      :sortcolumn="sortcolumn"
      :sortorder="sortorder"
      :currentpage="currentpage"
      :tutteLeStagioni="tutteLeStagioni"
      :denominazione_societa="denominazione_societa"
      :denominazione_omologatore="denominazione_omologatore"
      @resetFilters="resetFilters"
      @resetRowsToSkip="resetRowsToSkip"
      @setFetchRows="setFetchRows"
      @setCurrentPage="setCurrentPage"
      @setSortOrder="setSortOrder"
      @setDisciplina="setDisciplina"
      @setIdSocieta="setIdSocieta"
      @setIdOmologatore="setIdOmologatore"
    />
  </div>
</template>

<script>
import OmologazioniList from "@/components/components-fit/omologazioni/OmologazioniList.vue";

import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "RihciesteOmologazioniList",
  components: {
    OmologazioniList,
  },
  setup() {
    const store = useStore();

    const resetFilters = () => {
      store.commit("resetFiltersInCaricoOmologazione");
    };

    const resetRowsToSkip = () => {
      store.commit("setrowstoskipInCaricoOmologazione");
    };

    const setFetchRows = (e) => {
      store.commit("setfetchrowsInCaricoOmologazione", e);
    };
    const setCurrentPage = (page) => {
      store.commit("setcurrentpageInCaricoOmologazione", page);
    };
    const setSortOrder = (columnName, order) => {
      store.commit("setsortcolumnInCaricoOmologazione", columnName);
      store.commit("setsortorderInCaricoOmologazione", order);
    };

    //FITLRI
    const setDisciplina = (value) => {
      store.commit("setdisciplineInCaricoOmologazione", value);
    };

    const setIdSocieta = ({ value, name }) => {
      store.commit("setid_societaInCaricoOmologazione", value);
      store.commit("setdenominazioneSocietaInCaricoOmologazione", name);
    };

    const setIdOmologatore = ({ value, name }) => {
      store.commit("setid_omologatoreInCaricoOmologazione", value);
      store.commit("setdenominazioneOmologatoreInCaricoOmologazione", name);
    };

    return {
      id_societa: computed(() => store.getters.id_societaInCaricoOmologazione),
      id_omologatore: computed(
        () => store.getters.id_omologatoreInCaricoOmologazione
      ),
      tipologie: computed(() => store.getters.tipologieInCaricoOmologazione),
      id_disciplina: computed(
        () => store.getters.disciplineInCaricoOmologazione
      ),
      denominazione_societa: computed(
        () => store.getters.denominazioneSocietaInCaricoOmologazione
      ),
      denominazione_omologatore: computed(
        () => store.getters.denominazioneOmologatoreInCaricoOmologazione
      ),
      rowstoskip: computed(() => store.getters.rowstoskipInCaricoOmologazione),
      fetchrows: computed(() => store.getters.fetchrowsInCaricoOmologazione),
      sortcolumn: computed(() => store.getters.sortcolumnInCaricoOmologazione),
      sortorder: computed(() => store.getters.sortorderInCaricoOmologazione),
      currentpage: computed(
        () => store.getters.currentpageInCaricoOmologazione
      ),
      tutteLeStagioni: computed(
        () => store.getters.tutteLeStagioniInCaricoOmologazione
      ),
      resetFilters,
      resetRowsToSkip,
      setFetchRows,
      setCurrentPage,
      setSortOrder,
      setDisciplina,
      setIdOmologatore,
      setIdSocieta,
    };
  },
});
</script>
